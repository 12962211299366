<template>
  <div class="footer">
    <div class="footer-box">
      <!-- <h1>关注《东方归言录》掌握最新游戏资讯</h1> -->

      <div class="logo">
        <!-- <a class="logo1" target="_blank" href="https://www.gg.com/">
          <img src="https://thcdn.gggamedownload.com/source/assets/logo.png" alt="">
        </a> -->
        <div class="bottom">
          <a class="logo2">
            <img src="https://thcdn.gggamedownload.com/source/assets/logo-gsc.png" alt="">
          </a>
          <a class="logo3">
            <img style="margin-left:15px;" src="https://thcdn.gggamedownload.com/source/assets/logo-ninja.png" alt="">
          </a>
        </div>
      </div>
      <div class="bom">
        <p>东方LostWord是以上海爱丽丝幻乐团的游戏作品「东方Project」的原作为基点的二次创作作品</p>
        <p>本作品是以上海爱丽丝幻乐团的游戏作品「东方Project」为原点，在获得其许可的基础上，加入了各类创意及设定的作品。</p>
        <p>「东方Project」以及相关标题都为上海爱丽丝幻乐团的著作物</p>
      </div>
    </div>
  </div>
</template>
<script>
const link1 = 'https://thcdn.gggamedownload.com/source/assets/weibo.png'
const link2 = 'https://thcdn.gggamedownload.com/source/assets/QQ.png'
const link3 = 'https://thcdn.gggamedownload.com/source/assets/wechat.png'
const link4 = 'https://thcdn.gggamedownload.com/source/assets/baidu.png'
const link5 = 'https://thcdn.gggamedownload.com/source/assets/bili.png'
export default {
  name: 'Footer',
  data() {
    return {
      linkList: [
        {
          id: 1,
          path: link1,
          title: '官方微博',
          link: '@东方归言录',
          a: 'https://weibo.com/u/7490517968'
        },
        {
          id: 2,
          path: link2,
          title: '官方QQ群',
          link: '东方归言录'
        },
        {
          id: 3,
          path: link3,
          title: '官方公众号',
          link: '东方归言录'
        },
        {
          id: 4,
          path: link4,
          title: '百度贴吧',
          link: '东方归言录吧',
          a: 'https://tieba.baidu.com/f?kw=%B6%AB%B7%BD%B9%E9%D1%D4%C2%BC&fr=ala0&tpl=5'
        },
        {
          id: 5,
          path: link5,
          title: 'bilibili',
          link: '东方归言录',
          a: 'https://space.bilibili.com/1120919518'
        }
      ],
      active_weixinID: null,
      active_QQID: null,
      active_QQ: false
    }
  },
  methods: {
    handleMousemove({ id }) {
      if (id === 3) {
        this.active_weixinID = id
        this.active_QQID = false
      }
      if (id === 2) {
        this.active_weixinID = null
        this.active_QQID = id
        this.active_QQ = true
      }
    },
    handleClickQQ({ id }, active_QQ) {
      if (id === 2) {
        // this.active_QQID = null
        this.active_QQID = id
        this.active_QQ = !active_QQ
      }
    },
    handleMouseleave() {
      this.active_weixinID = null
      this.active_QQ = false
      // this.active_QQID = null
      // if (id === 2) {
      //   this.active_QQID = id
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  // height: 940px;
  background: url('https://thcdn.gggamedownload.com/source/assets/zhuyeBG/BG-6.jpg') no-repeat center;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-box {
    width: 856px;
    height: 856px;
    text-align: center;
    h1 {
      color: #93a4b1;
      padding-top: 80px;
    }
    .link {
      padding-top: 83px;
      img {
        width: 87px;
      }
      ul {
        display: flex;
        justify-content: space-around;
        li {
          cursor: pointer;
          position: relative;
          p {
            color: #fff;
          }
          .p {
            padding-top: 8px;
            color: #93a4b1;
          }

          .hover {
            position: absolute;
            width: 200px;
            top: 0;
            left: 50%;
            transform: translateX(-50%) translateY(-105%);
            text-align: center;
            color: #fff;
            &.QQhover {
              cursor: text;
              padding: 10px 0;
               background-color: #fff;
              color: #000;
              border-radius: 30px;
            }
            img {
              width: 161px;
            }
          }
        }
      }
    }
    .logo {
      padding-top: 60px;
      a {
        display: inline-block;
      }
      .logo1 {
        width: 415px;
      }
      .logo2{
        width: 222px;
      }
      .logo3 {
        width: 229px;
      }
      img {
        width: 100%;
      }
      .bottom {
        padding-top: 48px;

      }
    }
    .bom {
      cursor: pointer;
      padding-top: 90px;
      font-size: 30px;
      color: #93a4b1;
      p {
        height: 150px;
      }
    }
  }
}
</style>

